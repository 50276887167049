import fetch from "cross-fetch";
import { ApiConfig } from "types";

export const UPLOAD_ENDPOINT = process.env.REACT_APP_FILESTORAGE_URL;

export interface FormMediaValues {
  name: string;
  description: string;
  file: any;
}

// Form Layout
export const formItemLayout = {
  labelCol: {
    span: 6
    // xs: { span: 24 }
    // sm: { span: 4 },
    // md: { span: 4 },
    // lg: { span: 4 },
    // xl: { span: 4 }
  },
  wrapperCol: {
    span: 18
    // xs: { span: 24 }
    // sm: { span: 21 },
    // md: { span: 17 },
    // lg: { span: 17 },
    // xl: { span: 17 }
  }
};

// Normalize data for endpoint
export const normalizeValues = (values: FormMediaValues): any => {
  const { name, description, file } = values;

  // Waiting for file to be uploaded
  if (file && file[0].response) {
    const { type, response, size } = file[0];

    return {
      name,
      content_type: type,
      url: response.url,
      description,
      size,
      width: response.attributes.width,
      height: response.attributes.height
    };
  }

  return values;
};

// Normalize file on upload
export const normalizeFile = (file: any) => {
  if (Array.isArray(file)) {
    return file;
  }

  if (file.fileList.length > 1) {
    file.fileList.shift();
  }

  return file && file.fileList;
};

export const createMedia = async (api: ApiConfig, data: any): Promise<any> => {
  const reqInit: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Token ${api.tokens?.access}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };
  let response = null;

  try {
    const res = await fetch(`${api.baseUrl}/media/create/`, reqInit);

    if (res.ok) {
      response = await res.json();
    }
  } catch (e) {
    throw e;
  }

  return response;
};

export const getMedia = async (api: ApiConfig, id: any): Promise<any> => {
  const reqInit: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Token ${api.tokens?.access}`
    }
  };
  let response = null;

  try {
    const res = await fetch(`${api.baseUrl}/media/get/${id}/`, reqInit);

    if (res.ok) {
      response = await res.json();
    }
  } catch (e) {
    throw e;
  }

  return response;
};
